import React from "react"
import { Copyright, Disclaimer, BottomFooterLink } from "./FooterStyle"
import { useLocation } from "@reach/router"

const BottomFooter = ({ props, propsLink }) => {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const isSg =
    location.pathname.includes("/sg") ||
    location.pathname.includes("/singapore")
  const serviceLink = isSg
    ? isEnglish
      ? `/en/sg/service-agreement`
      : `/sg/service-agreement`
    : propsLink.Service
  const privacyLink = isSg
    ? isEnglish
      ? `/en/sg/privacy-policy`
      : `/sg/privacy-policy`
    : propsLink.Privacy
  return (
    <>
      <hr className="my-4" />
      <Copyright>
        Copyright © PT Indo Koala Remittance 2025 - All rights reserved |{" "}
        <BottomFooterLink to={serviceLink}>Service Agreement</BottomFooterLink>{" "}
        | <BottomFooterLink to={privacyLink}>Privacy Policy</BottomFooterLink>
      </Copyright>
      <Disclaimer>
        {isSg ? (
          <>
            {props.Disclaimer.DescSg1}
            <br />
            {props.Disclaimer.DescSg2}
          </>
        ) : (
          <>
            {props.Disclaimer.Desc}{" "}
            <a href="https://www.bi.go.id/id/qrcode/validate.aspx?id=0001.001/0024&tipe=PTD">
              {props.Disclaimer.License}
            </a>
          </>
        )}
      </Disclaimer>
    </>
  )
}
export default BottomFooter
